window.openStopEnrolmentModal = btn => {
  const stopEnrolmentOverlay = btn.nextElementSibling
  stopEnrolmentOverlay.classList.remove('hidden')
  stopEnrolmentOverlay.classList.add('flex')
}

window.closeStopEnrolmentModal = btn => {
  const stopEnrolmentOverlay = btn.closest('#stop-enrolment-overlay')
  stopEnrolmentOverlay.classList.remove('flex')
  stopEnrolmentOverlay.classList.add('hidden')
}
