document.addEventListener("turbo:load", function() {
  const timeslotContainer = document.getElementById('timeslot-container');
  const allScheduleBtn = document.getElementById('all-schedules');
  allScheduleBtn.onclick = function () {
    if (allScheduleBtn.checked === true) {
      timeslotContainer.classList.add('hidden');
    } else {
      timeslotContainer.classList.remove('hidden');
    }
  }
})
