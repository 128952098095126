window.displayFile = input => {
  if (fileValidation()){
    console.log("checking?")
    const label = input.nextElementSibling;
    const infoArea = label.nextElementSibling;
    infoArea.innerHTML = input.files[0].name + ' is uploaded';
    infoArea.classList.remove("hidden");
  }
}

window.displayFileFirst = input => {
  if (fileValidation()){
    const label = input.closest(".wrapper").nextElementSibling;
    const infoArea = label.nextElementSibling;
    infoArea.innerHTML = input.files[0].name + ' is uploaded';
    infoArea.classList.remove("hidden");
  }
}

// similar to displayfilefirst but added background for files uploaded, used in manage (awards and certs)
window.manageDisplayFileFirst = input => {
  const fileField = input.closest(".wrapper").firstElementChild;
  if (fileValidation(fileField)){
    console.log("ENTERED")
    const label = input.closest(".wrapper").nextElementSibling;
    const infoArea = label.querySelector(".file-upload-filename");
    infoArea.innerHTML = input.files[0].name;
    infoArea.classList.remove("hidden");
  }
}

window.displayLogo = input => {
  const label = input.nextElementSibling;
  const infoArea = label.nextElementSibling;
  infoArea.innerHTML = input.files[0].name + ' is uploaded';
  const output = input.closest(".ancestor").querySelector(".teacher-card-pic");

  const reader = new FileReader();
  reader.addEventListener('load', event => {
    output.src = event.target.result;
  });
  reader.readAsDataURL(input.files[0]);
}

function fileValidation(fileField) {
  var filePath = fileField.value;


  // Allowing file type
  var allowedExtensions =
          /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

  if (!allowedExtensions.exec(filePath)) {
    alert('Invalid file type. Only jpeg or png files are supported.');
    fileField.value = '';
    return false;
  }

  const maxFileSize = 1024 * 1024 * 2//  1024 * 1024 = 1MB
  for (let i = 0; i < fileField.files.length; i++) {
    if (fileField.files[i].size > maxFileSize) {
      // event.preventDefault()
      alert("Invalid file size. Only files up to size 2MB are supported.");
      fileField.value = '';
      return false;
    }
  }
  return true;
}
