import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, index } from 'instantsearch.js/es/widgets';

document.addEventListener("turbo:load", function() {
  const searchClient = algoliasearch(process.env.ALGOLIASEARCH_APPLICATION_ID, process.env.ALGOLIASEARCH_API_KEY_SEARCH);
  const search_announcement = instantsearch ({
    indexName: 'Announcement',
    searchClient,
  });

  search_announcement.addWidgets([
    searchBox({
      container: "#search_announcement",
      placeholder: "Any particular name?",
    }),
    hits({
      container: "#hits_announcements",
      templates: {
        empty: '<div> No matching school found.</div>',
        item:
          `<div class="box-border border border-eerie-black-20 rounded p-5 my-5">
            <div class="subtitle-4 font-semibold text-egyptian-blue">TO: Swimming Basic Lesson, Monday 1230pm</div>
            <h6 class="h6 my-5">What is movement 2030?</h6>
            <div class="p2 mb-5">s a dead rat for attempt to leap between furniture but woefully miscalibrate and bellyflop onto the floor; what's your problem? i meant to do that now i shall wash myself intently and destroy house in 5 seconds pretend</div>
            <div class="caption-1">Announced by Jonathan Lau, on 09/19/21  3:47pm</div>
          </div>
          <div class="box-border border border-eerie-black-20 rounded p-5 my-5">
            <div class="subtitle-4 font-semibold text-egyptian-blue">TO: Swimming Basic Lesson, Monday 1230pm</div>
            <h6 class="h6 my-5">Hello world</h6>
            <div class="p2 mb-5">Vel deserunt maiores</div>
            <div class="caption-1">Announced by Jonathan Lau, on 09/18/21 12:05pm</div>
          </div>
          <div class="box-border border border-eerie-black-20 rounded p-5 my-5">
            <div class="subtitle-4 font-semibold text-egyptian-blue">TO: Swimming Basic Lesson, Monday 1230pm</div>
            <h6 class="h6 my-5">Paloe tech nerdists</h6>
            <div class="p2 mb-5">Eum deserunt enim qu</div>
            <div class="caption-1">Announced by Jonathan Lau, on 09/18/21 12:38pm</div>
          </div>`
        },
      }),
    ]);
  search_announcement.start();
});

