document.addEventListener("turbo:load", function() {
  const sidebar = document.querySelector('.collapse-sidebar') || document.querySelector('.sidebar-small');
  const arrow = document.querySelector('.arrow-back')
  arrow.onclick = function () {
    if (arrow.innerHTML == 'navigate_before') {
      sidebar.classList.replace('collapse-sidebar', 'sidebar-small');
      arrow.innerHTML = 'chevron_right';
      //code to change width of message when closing sidebar
      const recipient = document.querySelectorAll(".recipient");
      if (recipient.length > 0) {
        for (let i = 0; i < recipient.length; i++) {
          recipient[i].classList.add("recipient-closed");
          recipient[i].classList.remove("recipient");
          recipient[i].nextSibling.classList.add("hover-action-recipient-closed");
          recipient[i].nextSibling.classList.remove("hover-action-recipient");
        }
      }
      const sender = document.querySelectorAll(".sender");
      if (sender.length > 0) {
        for (let i = 0; i < sender.length; i++) {
          sender[i].classList.add("sender-closed");
          sender[i].classList.remove("sender");
          // sender[i].nextSibling.classList.add("hover-action-sender-closed");
          // sender[i].nextSibling.classList.remove("hover-action-sender");

        }
      }
    } else {
      sidebar.classList.replace('sidebar-small', 'collapse-sidebar');
      arrow.innerHTML = 'navigate_before';
      //code to change width of message when opening sidebar
      const recipient = document.querySelectorAll(".recipient-closed");
      if (recipient.length > 0) {
        for (let i = 0; i < recipient.length; i++) {
          recipient[i].classList.remove("recipient-closed");
          recipient[i].classList.add("recipient");
          recipient[i].nextSibling.classList.remove("hover-action-recipient-closed");
          recipient[i].nextSibling.classList.add("hover-action-recipient");
        }
      }
      const sender = document.querySelectorAll(".sender-closed");
      if (sender.length > 0) {
        for (let i = 0; i < sender.length; i++) {
          sender[i].classList.remove("sender-closed");
          sender[i].classList.add("sender");
        }
      }
    } 
  }
})
