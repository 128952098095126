// used in manage/users page to add styling to the checkbox
window.manage_checkbox_admin = checkbox => {
  console.log(document.querySelectorAll("[id^='admin_']"))
  if (checkbox.innerHTML == "check_box") {
    // if checkbox is checked, means user wants to remove admin role
    // need to check if user has at least school teacher role before unchecking
    let teacher_checkbox = checkbox.closest(".admin-checkbox").nextElementSibling.querySelector(".teacher-role-checkbox")
    if (teacher_checkbox.innerHTML == "check_box") {
      // TEMPORARY: disallow unchecking of school_admin
      alert("Please contact support to remove admin role.");
      // to check if document.querySelectorAll(".admin-checkbox").length < 0
      // or prevent first school_admin from deselect forever document.querySelectorAll("[id^='admin_']")[0]
      // user already has teacher role, safe to uncheck
      // checkbox.classList.remove("text-emerald-100");
      // checkbox.innerHTML = "check_box_outline_blank";
    } else {
      // user does not have teacher role, admin role will not be removed, show error msg
      alert("A user must at least have one role.");
      // using alert for now, will change to error modal pop up once design is done, code below is for the pop up
      // checkbox.closest(".admin-and-teachers").querySelector('.role-error-message').classList.remove("hidden"); 

    }
  } else {
    // if checkbox was checked, means user wants to add admin role
    checkbox.classList.add("text-emerald-100");
    checkbox.innerHTML = "check_box";
  }
}

window.manage_checkbox_teacher = checkbox => {
  if (checkbox.innerHTML == "check_box") {
    // if checkbox is checked, means user wants to remove teacher role
    // need to check if user at least has school admin role before unchecking
    let admin_checkbox = checkbox.closest(".teacher-checkbox").previousElementSibling.querySelector(".admin-role-checkbox")
    if (admin_checkbox.innerHTML == "check_box") {
      // user already has admin role, safe to uncheck
      checkbox.classList.remove("text-emerald-100");
      checkbox.innerHTML = "check_box_outline_blank";
    } else {
      // user does not have admin role, teacher role will not be removed, show error msg
      alert("A user must at least have one role.");
      // using alert for now, will change to error modal pop up once design is done, code below is for the pop up
      // checkbox.closest(".admin-and-teachers").querySelector('.role-error-message').classList.remove("hidden");
    }
  } else {
    // if checkbox was checked, means user wants to add teacher role
    checkbox.classList.add("text-emerald-100");
    checkbox.innerHTML = "check_box";
  }
}

// used to close warning message
window.closeModal = close => {
  close.closest('.role-error-message').classList.add("hidden")
}
