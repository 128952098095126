document.addEventListener("turbo:load", function() {
  const switchbtnArray = document.querySelectorAll('.slider');
  const textboxArray = document.querySelectorAll('.textbox');

  switchbtnArray.forEach((switchbtn) => {
    switchbtn.onclick = function () {
      let textbox = switchbtn.closest(".ancestor").querySelector(".textbox");
      if(textbox.classList.contains("hidden")) {
        textbox.classList.remove('hidden');
      } else {
        textbox.classList.add('hidden');
      }
    } 
  });   
});
