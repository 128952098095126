document.addEventListener("turbo:load", () =>  {
  const agerange = document.querySelector('.agerange')
  const minage = document.querySelector('.minage')
  const maxage = document.querySelector('.maxage')
  const agerequirement = document.querySelector('.agerequirement')

  agerequirement.onchange = (e) => {
    if (e.target.value == 'any_age') {
      agerange.classList.add("hidden")
    }
    else if (e.target.value == 'age_range') {
      agerange.classList.remove("hidden")
      maxage.classList.remove("hidden") 
    }
    else {
      agerange.classList.remove("hidden")
      maxage.classList.add("hidden")
    } 
  }
})
