document.addEventListener("turbo:load", () =>  {
  // --------------------------------- New Emergency Contact -----------------------
  const announcementOverlay = document.querySelector('#a-overlay')
  const aBtn = document.querySelector('#a-btn')
  const closeAnnouncementMdl = document.querySelector('#close-a-modal')

  aBtn.addEventListener('click', () => {
    announcementOverlay.classList.remove('hidden')
    announcementOverlay.classList.add('flex')
  })

  closeAnnouncementMdl.addEventListener('click', () => {
    announcementOverlay.classList.add('hidden')
    announcementOverlay.classList.remove('flex')
  })
});
