// used in both manage and registration; mostly css styling of the checkbox to hide textbox if untick, and show textbox if ticked
document.addEventListener("turbo:load", function() {
  const checkboxArray = document.querySelectorAll('.checkbox');
  const textboxArray = document.querySelectorAll('.textbox');
  checkboxArray.forEach((checkbox) => {
    let textbox = checkbox.closest(".ancestor").querySelector(".textbox");
    if (textbox.innerHTML != "") {
      checkbox.previousSibling.checked = true;
      textbox.classList.remove('hidden');
    }
    checkbox.onclick = function () {
      if(textbox.classList.contains("hidden")) {
        textbox.classList.remove('hidden');
      } else {
        textbox.classList.add('hidden');
      }
    } 
  });   
});

window.tickCheckbox = input => {
  let textbox = input.closest(".ancestor").querySelector(".textbox");
  if(textbox.classList.contains("hidden")) {
    textbox.classList.remove('hidden');
  } else {
    textbox.classList.add('hidden');
  }
}
