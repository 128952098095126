document.addEventListener("turbo:load", function() {
  let allBtn = document.querySelectorAll('.rate')
  for (let i = 0; i < allBtn.length; i++){
    allBtn[i].onclick = function () {
      allBtn.forEach((starBtn) => {
        if (starBtn.nextElementSibling.classList.contains('text-jonquil-100')){
          starBtn.nextElementSibling.classList.remove('text-jonquil-100')
        }
      })
      for (let j= 0; j<i; j++){
        allBtn[j].nextElementSibling.classList.add('text-jonquil-100')
      }
    }
  }
})

