// autocomplete
import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

document.addEventListener("turbo:before-visit", function() {
  // to prevent duplicate rendering of search bar
  if (window.location.pathname == "/") {
    Turbo.clearCache();
  };
});

document.addEventListener("turbo:load", function() {
  const searchClient = algoliasearch(process.env.ALGOLIASEARCH_APPLICATION_ID, process.env.ALGOLIASEARCH_API_KEY_SEARCH);
  autocomplete({
    container: '#autocomplete',
    detachedMediaQuery: 'none',
    openOnFocus: false,   // dropdown appears once search bar clicked
    placeholder: 'What class are you looking for?',
    getSources({ query }) {
      return [
        // return timeslots
        {
          sourceId: 'courses',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: 'Course',
                  query,
                  params: {
                    hitsPerPage: 5,
                  },
                }
              ]
            });
          },
          getItemInputValue({ item }) {
            return item.name;
          },
          getItemUrl({ item }) {
            return "/marketplace/courses?Course[query]=" + item.name;
          },
          templates: {
            item({ item, createElement }) {
              // dropdown items are links by default
              return createElement('div', {
                dangerouslySetInnerHTML: {
                  __html: `<a href="/marketplace/courses?Course[query]=${item.name}"><div style="width:100%;">${item.name}</div></a>`,
                },
              });
            },
          },
        }
      ];
    },
  });
  // redirect to search courses results after pressing enter or clicking the button
  document.querySelector("#autocomplete form").addEventListener("submit", function(e) {
    Turbo.visit("/marketplace/courses?Course[query]=" + document.querySelector("#autocomplete input").value);
  });
});
