document.addEventListener("turbo:load", function() {
  // obtain submit button and disable it
  const submit_button = document.getElementById("submit-testimony");
  submit_button.disabled = true;
})

window.disable_testimony = click_star => {
  const submit_button = click_star.closest(".stars").querySelector("#submit-testimony")
  submit_button.disabled = false;
}
