
document.addEventListener("turbo:load", () =>  {
  const input = document.getElementById( 'user_accounts_attributes_0_school_attributes_photos' );
  const infoArea = document.getElementById( 'file-upload-filename');
  input.addEventListener( 'change', showFileName );
  function showFileName( event ) {   
    let input = event.srcElement;    
    let files = input.files;
    infoArea.innerHTML = 'Files uploaded are:' 
    for (var i = 0; i < files.length; i++) {
      infoArea.innerHTML += '<br/>' + files[i].name;
    }   
  }
})
