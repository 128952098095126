const Uppy = require("@uppy/core");
const Dashboard = require("@uppy/dashboard");
const ActiveStorageUpload = require("@excid3/uppy-activestorage-upload");

require("@uppy/core/dist/style.css");
require("@uppy/dashboard/dist/style.css");

const createPhotosGallery = (uppy, element, field_name) => {
  uppy.on('complete', (res) => {
    res.successful.forEach(file => {
      appendUploadedFile(element, file, field_name);
      // This is for selecting primary image on save
      createRadioButtonWithImages(element, file, "create");
    })
  });
}

const editPhotosGallery = (uppy, element, field_name) => {
  uppy.on('complete', (res) => {
    console.log("IM HERE 2");
    res.successful.forEach(file => {
      appendUploadedFile(element, file, field_name);
      // This is for selecting primary image on save
      createRadioButtonWithImages(element, file, "edit");
    })
  });
}

const setupUppy = (element) => {
  let trigger = element.querySelector('[data-behavior="uppy-trigger"]');
  let form = element.closest('form');
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute('content');
  const wrapperClass = document.querySelector(".wrapper");
  let field_name = wrapperClass.dataset.uppy;

  let uppy = new Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    logger: Uppy.debugLogger,
    restrictions: {
      // 5MB max size
      maxFileSize: 5 * 1024 * 1024,
      maxNumberOfFiles: 100,
      minNumberOfFiles: null,
      // Only allow images
      allowedFileTypes: ['image/*']
    },
  });

  uppy.use(ActiveStorageUpload, {
    directUploadUrl: direct_upload_url
  });

  uppy.use(Dashboard, {
    inline: true,
    id: 'Dashboard',
    theme: 'light',
    target: '.dashboard-body',
    note: 'Images and video only, 2–3 files, up to 2 MB',
    width: 650,
    height: 300,
    thumbnailWidth: 280,
    metaFields: [
      { id: 'name', name: 'Name', placeholder: 'file name' }
    ]
  });

  if(document.querySelector(".createPhotosGallery") !== null){
    createPhotosGallery(uppy, element, field_name);
  } else {
    editPhotosGallery(uppy, element, field_name);
  }
}

const appendUploadedFile = (element, file, field_name) => {
  const wrapperClass = document.querySelector(".wrapper");
  const hiddenField = document.createElement("input");

  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', field_name);
  hiddenField.setAttribute('data-pending-upload', true);
  hiddenField.setAttribute('value', file.response.id);

  wrapperClass.appendChild(hiddenField);
}

const createRadioButtonWithImages = (element, file, page) => {
  let previewImage = document.getElementById('previewImages');
  // Only show header when page is on create
  if (page == "create") {
    let headerForPreivew = document.getElementById('headerForPreview');
    // Display header
    headerForPreivew.classList.remove("hidden");
  }

  // Add col-span-1 and mr-3
  let labelWrapper = document.createElement("div");
  labelWrapper.className = "col-span-1 mr-3"

  // Creating labels
  let label = document.createElement("label");
  label.for = file.response.id;

  // Creating radio buttons
  let radioButton = document.createElement("input");
  radioButton.type = "radio";
  radioButton.name = "primaryImage";
  radioButton.value = file.response.id
  radioButton.id = file.response.id;
  radioButton.className = "my-3 radio-buttons";

  // Creating images
  let image = document.createElement("img");
  image.src = file.preview;
  image.style.width = 600;
  image.className = "h-auto rounded";

  // Append both radio buttons and images to label, then append label to previewImage
  label.appendChild(radioButton);
  label.appendChild(image);
  labelWrapper.appendChild(label);

  previewImage.appendChild(labelWrapper);
}

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.dashboard-body').forEach((element) => setupUppy(element));
});