document.addEventListener("turbo:load", () =>  {
  // --------------------------------- New Emergency Contact -----------------------
  const editAnnouncementOverlay = document.querySelector('#edit-announcement-overlay')
  const editAnnouncementBtn = document.querySelector('#edit-announcement-btn')
  const closeEditAnnouncementMdl = document.querySelector('#close-edit-announcement-modal')

  editAnnouncementBtn.addEventListener('click', () => {
    editAnnouncementOverlay.classList.remove('hidden')
    editAnnouncementOverlay.classList.add('flex')
  })

  closeEditAnnouncementMdl.addEventListener('click', () => {
    editAnnouncementOverlay.classList.add('hidden')
    editAnnouncementOverlay.classList.remove('flex')
  })
});
