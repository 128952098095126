document.addEventListener("turbo:load", function() {
  // disable checkout button on load since no profiles will be selected
  const checkout = document.getElementById("checkout-button");
  // selects all the modals
  const forms = document.querySelectorAll('.backdrop-filter')
  const student_profile_id = document.querySelectorAll("[id^='selected-user']");
  const startDate = document.getElementById("start_date");

  checkout.addEventListener('click', () => {
    // on checking out, destroy all the modals to prevent interference in form submission
    forms.forEach(e => e.remove())
  })
  const anychecked = (e) => e.checked;
  if (Array.from(student_profile_id).some(anychecked)) {
    checkout.disabled = false;
  }
  else {
    // when a student is checked, set checkout button to true
    checkout.disabled = true;
  }

  let selectStartDate = startDate.options[startDate.selectedIndex]
  let lastSelectedStartDate = localStorage.getItem('startDate')
  if (lastSelectedStartDate) {
    startDate.value = lastSelectedStartDate
    // prompt user before closing/refresh the page to remove localStorage
    window.onbeforeunload = function() {
      localStorage.removeItem("startDate");
      return '';
    };
  }

  startDate.onchange = function () {
    lastSelectedStartDate = startDate.options[startDate.selectedIndex].value;
    localStorage.setItem('startDate', lastSelectedStartDate);
  }
})

//when a student profile/emergency contact is selected, function checks whether to disable the checkout button
window.disable_button = clicked_contact => {
  var can_checkout = false;
  const checkout = document.getElementById("checkout-button");
  const student_profiles = document.getElementsByName("student_profile_ids[]");
  for (var i = 0; i < student_profiles.length; i++) {
    // as long as one profile is selected the button cannot be disabled
    if (student_profiles[i].checked === true) {
      const emergency_contacts = document.getElementsByClassName("emergency-contacts-enrolments")
      for (var j = 0; j < emergency_contacts.length; j++) {
        if (emergency_contacts[j].checked === true) {
          const course_address = document.getElementsByClassName("course-address-enrolments")
          for (var k = 0; k < course_address.length; k++) {
            if (course_address[k].checked === true) {
              can_checkout = true;
              checkout.disabled = false;
              break;
            } else {
              checkout.disabled = true;
            }
          }
        }
      }
      if (can_checkout) {
        break;
      }
    } else {
      checkout.disabled = true;
    }
  }
}

window.disabled_profile_alert = profile => {
  if (profile.classList.contains('disable-checkbox')) {
    alert("This student is currently enrolled to this lesson!")
  }
}
