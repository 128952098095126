// used in management, when school admin upload pics in gallery it will appear
document.addEventListener("turbo:load", () =>  {
  const input = document.getElementById( 'user_accounts_attributes_0_school_attributes_photos' ) ?? document.getElementById( 'course_photos' ) ?? document.getElementById ( 'user_accounts_attributes_0_teacher_profile_attributes_photos' );
  let output = document.getElementById('upload-gallery-pics');
  input.addEventListener( 'change', showFileName );
  function showFileName( event ) {
    let input = event.srcElement;
    let files = input.files;
    output.innerHTML = "";
    for (var i = 0; i < files.length; i++) {
      let newImage = document.createElement('img');
      newImage.className = 'h-24 px-2 py-2';
      newImage.src = URL.createObjectURL(files[i]);
      let wrapperClass = document.createElement('div');
      wrapperClass.className = 'col-span-1';
      wrapperClass.appendChild(newImage);
      output.appendChild(wrapperClass)
    }
  }
})
