document.addEventListener("turbo:load", function() {
  const courseType = document.getElementById('course-type');
  const paymentType = document.getElementById('payment-type');
  const privatePricing = document.getElementById('private-pricings');
  const publicPricing = document.getElementById('public-pricings');
  const attendanceBasedWording = document.getElementById('attendance-based-wording');
  const fifthWeekDropDown = document.getElementById('include-fifth-week-dropdown')
  const privateNumClass = document.getElementById('num-private-class-charged')
  const groupDeposit = document.getElementById('group-deposit')
  const publicClassAmount = document.getElementById('course_plan_attributes_amount')
  const privateClassAmount = document.getElementById('course_plan_attributes_pricings_attributes_0_amount')
  const privateClassNumStudent = document.getElementById('course_plan_attributes_pricings_attributes_0_number_of_students')

  if (courseType.value === "true") {
    // When class is public on page load
    publicPricing.classList.remove('hidden');
    privatePricing.classList.add('hidden');
    // set all private related to false
    privatePricing.required = false;
    privateClassAmount.required = false
    privateClassNumStudent.required = false
    // set all public related to true and validate min. amount
    publicPricing.required = true;
    publicClassAmount.required = true
    publicClassAmount.min = 10
  }

  if (courseType.value === "false") {
    // When class is private on page load
    publicPricing.classList.add('hidden');
    privatePricing.classList.remove('hidden');
    // set all private related to true
    privatePricing.required = true;
    privateClassAmount.required = true;
    privateClassNumStudent.required = true;
    privateClassAmount.min = 10;
    privateClassNumStudent.min = 1;
    // set all public related to false
    publicPricing.required = false;
    publicClassAmount.required = false;
  }

  if (paymentType.value === "subscription"){
    fifthWeekDropDown.classList.remove('hidden');
    groupDeposit.classList.remove('hidden');
    attendanceBasedWording.classList.add('hidden');
    privateNumClass.classList.add('hidden');

  } else {
    fifthWeekDropDown.classList.add('hidden');
    groupDeposit.classList.add('hidden');
    attendanceBasedWording.classList.remove('hidden');
    privateNumClass.classList.remove('hidden');
  }

  paymentType.onchange = e => {
    if (e.target.value === "subscription"){
      // Show deposit on subscription
      groupDeposit.classList.remove('hidden');
      fifthWeekDropDown.classList.remove('hidden');
      privateNumClass.classList.add('hidden');
      attendanceBasedWording.classList.add('hidden');
    } else {
      // Hide deposit on attendance based
      groupDeposit.classList.add('hidden');
      fifthWeekDropDown.classList.add('hidden');
      privateNumClass.classList.remove('hidden');
      attendanceBasedWording.classList.remove('hidden');
    }
  }

  courseType.onchange = (e) => {
    // When public plan is chosen
    if (e.target.value === "true"){
      publicPricing.classList.remove('hidden');
      privatePricing.classList.add('hidden');
      // set all public related to true and set min value to 10
      publicPricing.required = true;
      publicClassAmount.required = true;
      publicClassAmount.min = 10;
      // set all private related to false
      privatePricing.required = false;
      privateClassAmount.required = false;
      privateClassNumStudent.required = false;

    } else {
      // Private plan
      publicPricing.classList.add('hidden');
      privatePricing.classList.remove('hidden');
      // set all private related to true and set min amount to 10 and student > 0
      privatePricing.required = true;
      privateClassAmount.required = true;
      privateClassNumStudent.required = true;
      privateClassAmount.min = 10;
      privateClassNumStudent.min = 1;
      // set all public related to false
      publicPricing.required = false;
      publicClassAmount.required = false;
    }
  }
});
