document.addEventListener("turbo:load", () =>  {
  // --------------------------------- New Question ------------------------------
  const overlay = document.querySelector('#overlay')
  const questBtn = document.querySelector('#question-btn')
  const closeMdl = document.querySelector('#close-modal')

  questBtn.addEventListener('click', () => {
    overlay.classList.remove('hidden')
    overlay.classList.add('flex')
  })

  closeMdl.addEventListener('click', () => {
    overlay.classList.add('hidden')
    overlay.classList.remove('flex')
  })
})
