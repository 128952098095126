window.addEventListener("scroll", event => {
  let mainNavLinks = document.querySelectorAll("a.side-anchor");
  let fromTop = window.scrollY + 5;
  
  mainNavLinks.forEach(link => {
    let section = document.querySelector(link.hash);
    if (
      section.offsetTop <= fromTop &&
      section.offsetTop + section.offsetHeight > fromTop
    ) {
      link.parentElement.classList.add("current");
    } else {
      link.parentElement.classList.remove("current");
    }
  });
});
