document.addEventListener("turbo:load", () =>  {
  // For nested venues in the course weekly schedules page

  // Following code is for the initial one venue modal on page load
  const parentNests = document.querySelectorAll('.parent-nest');
  const addBtn = document.querySelector('.add-location');
  parentNests.forEach((parentNest) => {
    const overlay = parentNest.querySelector('.overlay');
    const modalBtn = parentNest.querySelector('.modal-btn');
    const closeMdl = parentNest.querySelector('.close-modal');
    const deleteMdl = parentNest.querySelector('.delete-modal');
    const saveMdl = parentNest.querySelector('.save-modal');
    const venue = parentNest.querySelector('.venue');

    modalBtn.addEventListener('click', () => {
      overlay.classList.remove('hidden');
      overlay.classList.add('flex');
    })

    closeMdl.addEventListener('click', () => {
      overlay.classList.add('hidden');
      overlay.classList.remove('flex');
    })

    deleteMdl.addEventListener('click', () => {
      overlay.querySelectorAll(".address-fields").forEach((field) => {
        field.value = "";
      })
      venue.innerHTML = ""
      overlay.classList.add('hidden');
      overlay.classList.remove('flex');
    })

    saveMdl.addEventListener('click', () => {
      let address = [...parentNest.querySelectorAll(".address-fields")].map(x=>x.value);
      let regions = [...parentNest.querySelectorAll(".region-fields")].filter(word => word.checked).map(x=>x.getAttribute('data-region'));
      parentNest.querySelector(".venue").innerHTML = regions.concat(address).filter(item => item).join(", ");
      if (address[0] || !parentNest.querySelector(".address-fields").required) { // line 1 is not empty
        overlay.classList.add('hidden');
        overlay.classList.remove('flex');
      }
    })

    // Following code is for the new venue modals added
    addBtn.addEventListener('click', () => {
      setTimeout(function() {
        let newNested = addBtn.parentElement.previousSibling;
        let newOverlay = newNested.querySelector('.overlay');
        let newModalBtn = newNested.querySelector('.modal-btn');
        let newCloseMdl = newNested.querySelector('.close-modal');
        let newDeleteMdl = newNested.querySelector('.delete-modal');
        let newSaveMdl = newNested.querySelector('.save-modal');
        newModalBtn.addEventListener('click', () => {
          newOverlay.classList.remove('hidden');
          newOverlay.classList.add('flex');
        })

        newCloseMdl.addEventListener('click', () => {
          newOverlay.classList.add('hidden');
          newOverlay.classList.remove('flex');
        })

        newDeleteMdl.addEventListener('click', () => {
          newNested.querySelectorAll(".address-fields").forEach((field) => {
            field.value = "";
          })
          newNested.querySelector(".venue").innerHTML = ""
          newOverlay.classList.add('hidden');
          newOverlay.classList.remove('flex');
        })

        newSaveMdl.addEventListener('click', () => {
          let address = [...newNested.querySelectorAll(".address-fields")].map(x=>x.value);
          let regions = [...newNested.querySelectorAll(".region-fields")].filter(word => word.checked).map(x=>x.getAttribute('data-region'));
          newNested.querySelector(".venue").innerHTML = regions.concat(address).filter(item => item).join(", ");
          if (address[0] || !parentNest.querySelector(".address-fields").required) { // line 1 is not empty
            newOverlay.classList.add('hidden');
            newOverlay.classList.remove('flex');
          }
        })
      }, 500);
    })
  });
})
