window.limitNumCheckBoxSelected = (numLimit, checkbox) => {
  const parentNest = checkbox.closest('.parent-nest')
  const checkboxes = parentNest.querySelectorAll('input[type="checkbox"]');
  const selectedCheckboxes = [...checkboxes].filter(word => word.checked)
  if (selectedCheckboxes.length > numLimit) {
    for (let i = 0; i < selectedCheckboxes.length; i++) {
      if (selectedCheckboxes[i].value == checkbox.value) {
        // revert most recent selection if above limit
        selectedCheckboxes[i].checked = false;
      }
    }
  }
}
