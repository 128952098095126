document.addEventListener("turbo:load", () =>  {
  const testimonials = document.getElementsByClassName("line-clamp-testimonial");
  testimonials.forEach((t) => {
    if (!((t.offsetHeight < t.scrollHeight) || (t.offsetWidth < t.scrollWidth))) {
      // testimonial didnt overflow, hide the button
      t.nextElementSibling.classList.add("hidden")
    }
  })
});

window.readMoreTestimonials = button => {
  const testimonial = button.previousElementSibling;
  // expand the testimonial if it has been line clamped
  if (testimonial.classList.contains("line-clamp-testimonial")) {
    testimonial.classList.remove("line-clamp-testimonial");
    button.innerHTML = "Read less"
  } else {
    // let the user reduce the size of testimonial
    testimonial.classList.add("line-clamp-testimonial");
    button.innerHTML = "Read more"
  } 
}

