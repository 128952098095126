// used in manage, to display a parent's avatar
document.addEventListener("turbo:load", () =>  {
  const output = document.getElementById("profile-pic");
  document.querySelector('#user_avatar').addEventListener('change', (event) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', event => {
      output.src = event.target.result;
    });
    reader.readAsDataURL(file);
  });
});
