import { createDropdown } from '../../src/search/Dropdown';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, refinementList, rangeInput, numericMenu, clearRefinements, configure, sortBy, pagination, stats} from 'instantsearch.js/es/widgets';


document.addEventListener("turbo:load", function () {
  const searchClient = algoliasearch(process.env.ALGOLIASEARCH_APPLICATION_ID, process.env.ALGOLIASEARCH_API_KEY_SEARCH);
  const search = instantsearch({
    indexName: 'Course',
    searchClient,
    routing: false,

  });
  
  // 1. Create dropdown for category, as "subjects on frontend"
  const refinementListDropdown_category = createDropdown(
    refinementList,
    {
      buttonText: 'Subjects',
      cssClasses: { root: 'subjects_dropdown_css' },
    }
  );
  // 2. Create dropdown for location
  const refinementListDropdown_location = createDropdown(
    refinementList,
    {
      buttonText: 'Locations',
      cssClasses: { root: 'location_dropdown_css' },
    }
  );
  // 3. Create dropdown for price/session
  const rangeInputDropdown_price_session = createDropdown(
    rangeInput,
    {
      buttonText: 'Price / session',
      precision: 2,
    }
  );
  // 4. Create dropdown for ratings
  const numericMenuDropdown_ratings = createDropdown(
    numericMenu,
    {
      buttonText: 'Ratings',
      cssClasses: { root: 'ratings_dropdown_css' },
    }
  );
  // 5. Create dropdown for Timings
  const refinementListDropdown_timings = createDropdown(
    refinementList,
    {
      buttonText: 'Timings',
      cssClasses: { root: 'timings_dropdown_css' },
    }
  );
  // 6. Create dropdown for day_of_week, as "Days" on frontend
  const refinementListDropdown_day_of_week = createDropdown(
    refinementList,
    {
      buttonText: 'Day',
      cssClasses: { root: 'day_dropdown_css' },
    }
  );

  const filterValues = document.getElementById('filters').dataset;
  // Instantiate the custom widget
  search.addWidgets([
    configure({
      filters: filterValues.category ? `conditioned_category:'${filterValues.category}'` : undefined,
      hitsPerPage: 25
    }),
    searchBox({
      container: '#searchbox_category',
    }),
    hits({
      container: "#hits_category",
      templates: {
        empty: '<div>No matching course found.</div>',
        // all variables here with {{}} are attributes from algolia, can be found under Course model file for Algolia Search
        item:function(data) {
          if (data.published == true && data.educator_name) {
            return `
            <div class="div mx-auto w-full rounded-lg shadow">
              <div class="flex w-full sm:h-40 h-36">
                <div>
                  <img src="${data.course_url}/image" class="object-cover h-full sm:w-40 w-36">
                </div>
                <div class="p-3 w-full flex justify-between">
                  <div class="grid flex-1">
                    <div class="oh-3 mb-1">${data.conditioned_category} Lesson</div>
                    <a href="${data.course_url}">
                      <h3 class="h6 mb-2 btn-course-show capitalize overflow line-clamp h-auto text-base leading-5 sm:text-lg sm:leading-6">${data.name}</h3>
                    </a>
                    <div class="p4 sm:text-base sm:leading-6 mb-2">${data.price == null ? "Enquire for price" : "$" + data.price + "/" + data.interval}</div>
                    <h4 class="p4 line-clamp text-ellipsis overflow-hidden sm:text-base sm:leading-6 mt-auto">By ${data.educator_name}</h4>
                  </div>
                  <div class="grid w-32 sm:w-52">
                    <div class="p4 flex items-center subtitle-4 mb-2">
                      <div class="mr-1 text-eerie-black-100">${data.number_of_testimonials > 0 ? data.rating : 5}</div>
                      <img src="${data.stars}" class="w-20">
                      <div class="ml-1 text-eerie-black-100">(${data.number_of_testimonials})</div>
                    </div>
                    <div class="p4 line-clamp text-ellipsis overflow-hidden sm:text-base sm:leading-6">${data.multiple_venues ? "Multiple Locations" : data.display_venue}</div>
                    <a target="_blank" class="btn-whatsapp rounded font-semibold inline-flex mt-2 items-center justify-center" href="${data.mobile}"><i class="fab fa-whatsapp text-2xl mr-2 leading-6" aria-hidden="true"></i>Whatsapp</a>
                  </div>
                </div>
              </div>
            </div>`
          }
        }
      }
    }),

    pagination({
      container: '#pagination_container',
      scrollTo: '#hits_category', // Optional: Scroll to the hits container after changing page
      showFirst: false, // Optional: Hide first page button
      showLast: false, // Optional: Hide last page button
      labels: {
        previous: '‹ Previous',
        next: 'Next ›',
      },
      cssClasses: {
        root: 'pagination',
        item: 'pagination-item',
        link: 'pagination-link',
        active: 'pagination-link-active',
        disabled: 'pagination-link-disabled',
      },
    }),

    stats({
      container: '#stats_container',
      templates: {
        text: function(data) {
          const hitsText = `There ${data.nbHits > 1 || data.nbHits ===0 ? 'are' : 'is'} ${data.nbHits} ${filterValues.category} class${data.nbHits > 1 ? 'es' : ''}`;
          const locationText = filterValues.location ? ` in ${filterValues.location}` : '';
          return `${hitsText}${locationText}.`;
        },
      },
    }),

    
    refinementListDropdown_category({
      container: '#dropdown_category_category',
      attribute: "conditioned_category",
      searchable: true,
    }),
    refinementListDropdown_location({
      container: '#dropdown_category_location',
      attribute: "locations.region",
      searchable: true,
      showMore: true,
      sortBy: ['name:asc'],
    }),
    rangeInputDropdown_price_session({
      container: '#dropdown_category_price_session',
      attribute: "price_session.amount",
    }),
    numericMenuDropdown_ratings({
      container: '#dropdown_category_ratings',
      attribute: "rating",
      items: [
        { start: 5, label: '5.0' },
        { start: 4.0, end: 5.0, label: '4.0 and above' },
        { start: 3.0, end: 5.0, label: '3.0 and above' },
        { start: 2.0, end: 5.0, label: '2.0 and above' },
        { start: 1.0, end: 5.0, label: '1.0 and above' },
      ],
    }),
    refinementListDropdown_timings({
      container: '#dropdown_category_timing',
      attribute: "timing.time_of_day",
    }),
    refinementListDropdown_day_of_week({
      container: '#dropdown_category_day_of_week',
      attribute: "timeslots.day_of_week",
      searchable: false,
    })
  ]);

  // set the location query on page loads and make it able to be search afterwards
  let initialRender = true;
  let userClearedInput = false;
  const handleInput = () => {
    userClearedInput = true;
    search.off('input', handleInput);
  };
  const handleBlur = () => {
    if (!userClearedInput && searchBox.value.trim() === '') {
      searchBox.value = `${filterValues.location}`;
    }
  };
  const handleRender = () => {
    const searchBox = document.querySelector('#searchbox_category input');
    if (initialRender && searchBox) {
      console.log('Setting value:', `${filterValues.location}`);
      searchBox.value = `${filterValues.location}`;
      initialRender = false;

      // Add 'input' and 'blur' event listeners after setting initial value
      search.on('input', handleInput);
      searchBox.addEventListener('blur', handleBlur);

      // Trigger Algolia search after setting the initial value
      searchBox.dispatchEvent(new Event('input'));
    }
  };
  search.on('render', handleRender);
  search.start();
});
