document.addEventListener("turbo:load", () =>  {
  // --------------------------------- New Venue -----------------------
  const venueOverlay = document.querySelector('#venue-overlay')
  const venueBtn = document.querySelector('#venue-btn')
  const closeVenueMdl = document.querySelector('#close-venue-modal')

  venueBtn.addEventListener('click', () => {
    venueOverlay.classList.remove('hidden')
    venueOverlay.classList.add('flex')
  })

  closeVenueMdl.addEventListener('click', () => {
    venueOverlay.classList.add('hidden')
    venueOverlay.classList.remove('flex')
  })
});
