document.addEventListener("turbo:load", () =>  {
  const input = document.getElementById( 'course_photos' );
  const infoArea = document.getElementById( 'file-upload-filename');

  input.addEventListener( 'change', showFileName );
  function showFileName( event ) {   
    let input = event.srcElement;    
    let fileName = input.files[0].name;    
    infoArea.textContent = 'File name: ' + fileName;
    infoArea.classList.remove("hidden");
  }
})
