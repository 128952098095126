document.addEventListener("turbo:load", () =>  {
  const compare = document.getElementById('compareSubmit');
  const closeMdl = document.getElementById('close-modal');
  closeMdl.addEventListener('click', () => {
    compare.classList.add('hidden');
    compare.classList.remove('flex')
  })
})

//function for compare courses
window.compareBanner = clicked_id => {
  let count = 0;
  const checkboxes = document.getElementsByName("courses_ids[]");
  const currentId = "selected-course-" + clicked_id;
  const compare = document.getElementById('compareSubmit');
  compare.classList.remove('hidden');
  const compareCount = document.getElementById('compare-count');
  //count total number of checked checkboxes
  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked === true) {
      count++;
      checked(checkboxes[i]);
    }
    else { 
      uncheck(checkboxes[i]);
    }
  }
  //uncheck a checkbox if there are already 3 checked checkboxes
  if (count > 3) {
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].id === currentId && checkboxes[i].checked === true) {
        checkboxes[i].checked =  false;
        uncheck(checkboxes[i]);
        count--;
      }  
    }
  } 
  compareCount.innerHTML = count + " / 3 are selected";
}

//function for compare educators
window.compareBannerEducators = clicked_id => {
  let count = 0;
  const checkboxes = document.getElementsByName("profiles_ids[]");
  const currentId = "selected-profile-" + clicked_id;
  const compare = document.getElementById('compareSubmit');
  compare.classList.remove('hidden');
  const compareCount = document.querySelector('.compare-count');
  //count total number of checked checkboxes
  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked === true) {
      count++;
      checked(checkboxes[i]);
    }
    else { 
      uncheck(checkboxes[i]);
    }
  }
  //uncheck a checkbox if there are already 3 checked checkboxes
  if (count > 3) {
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].id === currentId && checkboxes[i].checked === true) {
        checkboxes[i].checked =  false;
        uncheck(checkboxes[i]);
        count--;
      }
    }
  } 
  compareCount.innerHTML = count + " / 3 are selected";
}

//function to change frontend styling, turning the checkbox green with green border 
const checked = checkbox => {
  const compareIcon = checkbox.closest('label').querySelector('.compare-checkbox');
  compareIcon.classList.remove('text-eerie-black-60');
  compareIcon.classList.add('text-emerald-100');
  compareIcon.innerHTML = "check_box";
  const compareContent = checkbox.closest('label').querySelector('.compare-content');
  compareContent.innerHTML = "Selected";
  const compareDiv = checkbox.closest('label').querySelector('.selected-content-compare');
  compareDiv.classList.add('border-emerald-100', 'border-2', 'bg-cyan-green');
}

//function to change frontend styling, returning the checkbox styling to default
const uncheck = checkbox => {
  const compareIcon = checkbox.closest('label').querySelector('.compare-checkbox');
  compareIcon.innerHTML = "check_box_outline_blank";
  compareIcon.classList.remove('text-emerald-100');
  compareIcon.classList.add('text-eerie-black-60');
  const compareContent = checkbox.closest('label').querySelector('.compare-content');
  compareContent.innerHTML = "Select to compare";
  const compareDiv = checkbox.closest('label').querySelector('.selected-content-compare');
  compareDiv.classList.remove('border-emerald-100', 'border-2', 'bg-cyan-green');
}
