import { createDropdown } from '../../src/search/Dropdown';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, refinementList, rangeInput , numericMenu, currentRefinements , clearRefinements, pagination, configure, sortBy, geoSearch } from 'instantsearch.js/es/widgets';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';
import { connectGeoSearch } from 'instantsearch.js/es/connectors';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Loader } from "@googlemaps/js-api-loader"

document.addEventListener("turbo:load", function() {
  const searchClient = algoliasearch(process.env.ALGOLIASEARCH_APPLICATION_ID, process.env.ALGOLIASEARCH_API_KEY_SEARCH);
  const search = instantsearch({
    indexName: 'Course',
    searchClient,
    routing: true,
  });

  const loader = new Loader({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    version: "weekly",
  });
  let markers = [];
  let isSecondRendering = false;
  let markerCluster = null;

  const renderGeoSearch = (renderOptions, isFirstRendering) => {
    const {
      items,
      currentRefinement,
      refine,
      clearMapRefinement,
      widgetParams,
    } = renderOptions;

    const {
      initialZoom,
      initialPosition,
      container,
    } = widgetParams;

    if (isFirstRendering) {
      loader.load().then(() => {
        const storedZoom = localStorage.getItem('mapZoom');
        const storedPosition = localStorage.getItem('mapPosition');
        const defaultZoom = 11;
        const defaultCenter = { lat: 1.3521, lng: 103.8198 };
        const map = new google.maps.Map(document.getElementById("gmaps"), {
          zoom: storedZoom ? parseInt(storedZoom) : defaultZoom,
          center: storedPosition ? JSON.parse(storedPosition) : defaultCenter,
          styles: [{
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          }]
        });
        google.maps.event.addListener(map, 'bounds_changed', function() {
          localStorage.setItem('mapZoom', map.getZoom());
          localStorage.setItem('mapPosition', JSON.stringify(map.getCenter()));
          var bounds =  map.getBounds();
          var ne = bounds.getNorthEast();
          var sw = bounds.getSouthWest();
          refine({
            northEast: { lat: ne.lat(), lng: ne.lng() },
            southWest: { lat: sw.lat(), lng: sw.lng() },
          });
          //do whatever you want with those bounds
        });
        window.map = map;
      });
    };

    if (!isFirstRendering) {
      loader.load().then(() => {
        const infoWindow = new google.maps.InfoWindow();
        markers = [];
        markers = items.map((data) => {
          const marker = new google.maps.Marker({
            position: data._geoloc,
            title: data.name,
          });
          marker.addListener("click", () => {
            infoWindow.close();
            infoWindow.setContent(
              `<div class="flex w-72 h-20 sm:h-24"><img class="object-cover w-20 h-20 sm:h-24 sm:w-24 mr-2 rounded-lg flex-shrink-0" src="${data.course_url}/image">
                <div class="grid w-full">
                  <div class="oh-3 leading-3">${data.conditioned_category} Lesson</div>
                  <a href="./courses/${data.objectID}">
                    <div class="h6 font-medium capitalize overflow line-clamp h-auto text-sm leading-4 sm:text-base sm:leading-5">${data.name}</div>
                  </a>
                  <div class="p4 truncate font-normal text-xs leading-3 sm:text-sm sm:leading-5">${data.multiple_venues ? "Multiple Locations" : data.display_venue}</div>
                  <div class="flex justify-between items-center p4 text-xs font-normal leading-3 sm:text-sm sm:leading-5">
                    <div>${data.price == null ? "Enquire for price" : "$" + data.price + "/" + data.interval}</div>
                    <div class="flex items-center">
                      <div class="mr-1">${data.number_of_testimonials > 0 ? data.rating : 5}</div>
                      <div class="material-icons text-eerie-black-60" style="font-size: 0.75rem !important">star</div>
                      <div class="ml-1">(${data.number_of_testimonials})</div>
                    </div>
                  </div>
                </div>
              </div>`
            );
            infoWindow.setOptions({disableAutoPan: true});
            infoWindow.open(map, marker);
          });
          return marker;
        });
        if (isSecondRendering) {
          markerCluster.clearMarkers();
        }
        // Add a marker clusterer to manage the markers.
        markerCluster = new MarkerClusterer({ markers, map, onClusterClick});
        function onClusterClick(cluster) {
          map.setCenter(cluster.latLng);
          map.setZoom(map.getZoom() + 4);
          let results = document.getElementById("hits_course");
          let btn = document.getElementById("toggle_btn");
          if (btn) {
            if (results.classList.contains("hidden")) {
              results.classList.remove("hidden");
              btn.innerHTML = "Map";
            } else {
              results.classList.add("hidden");
              btn.innerHTML = "List";
            }
          }
        }
        isSecondRendering = true;
      });
    };
  }

  // Create the custom widget
  const customGeoSearch = connectGeoSearch(
    renderGeoSearch
  );

  // 1. Create dropdown for category, as "subjects on frontend"
  const refinementListDropdown_category = createDropdown(
    refinementList,
    {
      buttonText: 'Subjects',
      cssClasses: { root: 'subjects_dropdown_css' },
    }
  );
  // 2. Create dropdown for location
  const refinementListDropdown_location = createDropdown(
    refinementList,
    {
      buttonText: 'Locations',
      cssClasses: { root: 'location_dropdown_css' },
    }
  );
  // 3. Create dropdown for price/session
  const rangeInputDropdown_price_session = createDropdown(
    rangeInput,
    {
      buttonText: 'Price / session',
      precision: 2,
    }
  );
  // 4. Create dropdown for ratings
  const numericMenuDropdown_ratings = createDropdown(
    numericMenu,
    {
      buttonText: 'Ratings',
      cssClasses: { root: 'ratings_dropdown_css' },
    }
  );
  // 5. Create dropdown for Timings
  const refinementListDropdown_timings = createDropdown(
    refinementList,
    {
      buttonText: 'Timings',
      cssClasses: { root: 'timings_dropdown_css' },
    }
  );
  // 6. Create dropdown for day_of_week, as "Days" on frontend
  const refinementListDropdown_day_of_week = createDropdown(
    refinementList,
    {
      buttonText: 'Day',
      cssClasses: { root: 'day_dropdown_css' },
    }
  );

  // Instantiate the custom widget
  search.addWidgets([
    configure({
      hitsPerPage: 500,
    }),
    searchBox({
      container: '#searchbox_course',
    }),
    hits({
      container: "#hits_course",
      templates: {
        empty: '<div>No matching course found.</div>',
        // all variables here with {{}} are attributes from algolia, can be found under Course model file for Algolia Search
        item:function(data) {
          if (data.published == true && data.educator_name) {
            return `
            <div class="div mx-auto w-full rounded-lg shadow">
              <div class="flex w-full sm:h-40 h-36">
                <div>
                  <img class="object-cover h-full sm:w-40 w-36" src="${data.course_url}/image">
                </div>
                <div class="p-3 w-full flex justify-between">
                  <div class="grid flex-1">
                    <div class="oh-3 mb-1">${data.conditioned_category} Lesson</div>
                    <a href="${data.course_url}">
                      <h3 class="h6 mb-2 btn-course-show capitalize overflow line-clamp h-auto text-base leading-5 sm:text-lg sm:leading-6">${data.name}</h3>
                    </a>
                    <div class="p4 sm:text-base sm:leading-6 mb-2">${data.price == null ? "Enquire for price" : "$" + data.price + "/" + data.interval}</div>
                    <h4 class="p4 line-clamp text-ellipsis overflow-hidden sm:text-base sm:leading-6 mt-auto">By ${data.educator_name}</h4>
                  </div>
                  <div class="grid w-32 sm:w-52">
                    <div class="p4 flex items-center subtitle-4 mb-2">
                      <div class="mr-1 text-eerie-black-100">${data.number_of_testimonials > 0 ? data.rating : 5}</div>
                      <img src="${data.stars}" class="w-20">
                      <div class="ml-1 text-eerie-black-100">(${data.number_of_testimonials})</div>
                    </div>
                    <div class="p4 line-clamp text-ellipsis overflow-hidden sm:text-base sm:leading-6">${data.multiple_venues ? "Multiple Locations" : data.display_venue}</div>
                    <a target="_blank" class="btn-whatsapp rounded font-semibold inline-flex mt-2 items-center justify-center" href="${data.mobile}"><i class="fab fa-whatsapp text-2xl mr-2 leading-6" aria-hidden="true"></i>Whatsapp</a>
                  </div>
                </div>
              </div>
            </div>`
          }
        }
      }
    }),
    customGeoSearch({
      container: document.getElementById("gmaps"),
      initialZoom: 10,
      initialPosition: {
        lat: 1.3521,
        lng: 103.8198,
      },
    }),
    refinementListDropdown_category({
      container: '#dropdown_course_category',
      attribute: "conditioned_category",
      searchable: true,
    }),
    refinementListDropdown_location({
      container: '#dropdown_course_location',
      attribute: "locations.region",
      searchable: true,
      showMore: true,
      sortBy: ['name:asc'],
    }),
    rangeInputDropdown_price_session({
      container: '#dropdown_course_price_session',
      attribute: "price_session.amount",
    }),
    numericMenuDropdown_ratings({
      container: '#dropdown_course_ratings',
      attribute: "rating",
      items: [
        { start: 5, label: '5.0' },
        { start: 4.0, end: 5.0, label: '4.0 and above' },
        { start: 3.0, end: 5.0, label: '3.0 and above' },
        { start: 2.0, end: 5.0, label: '2.0 and above' },
        { start: 1.0, end: 5.0, label: '1.0 and above' },
      ],
    }),
    refinementListDropdown_timings({
      container: '#dropdown_course_timing',
      attribute: "timing.time_of_day",
    }),
    refinementListDropdown_day_of_week({
      container: '#dropdown_course_day_of_week',
      attribute: "timeslots.day_of_week",
      searchable: false,
    })
  ]);
  search.start();
});
