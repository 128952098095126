document.addEventListener("turbo:load", () =>  {
  // --------------------------------- New Student Profile -----------------------
  const profileBtns = document.querySelectorAll('.profile-btn')
  const closeProfileMdls = document.querySelectorAll('.close-profile-modal')

  profileBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      let overlay = btn.closest(".student-profile-container").querySelector(".profile-overlay")
      overlay.classList.remove('hidden')
      overlay.classList.add('flex')
    })
  })

  closeProfileMdls.forEach(mdl => {
    mdl.addEventListener('click', () => {
      let overlay = mdl.closest(".student-profile-container").querySelector(".profile-overlay")
      overlay.classList.add('hidden')
      overlay.classList.remove('flex')
    })
  })
});
