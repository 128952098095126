import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, index, configure, pagination } from 'instantsearch.js/es/widgets';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

document.addEventListener("turbo:load", function() {
  const searchClient = algoliasearch(process.env.ALGOLIASEARCH_APPLICATION_ID, process.env.ALGOLIASEARCH_API_KEY_SEARCH);
  const search_educator = instantsearch ({
    indexName: 'SchoolTeacher',
    searchClient,
  });

  // infinite scroll
  let lastRenderArgs;

  const infiniteHits = connectInfiniteHits(
    (renderArgs, isFirstRender) => {
      const { hits, showMore, widgetParams } = renderArgs;
      const { container } = widgetParams;
      lastRenderArgs = renderArgs;

      if (isFirstRender) {
        const sentinel = document.createElement('div');
        container.appendChild(document.createElement('hits_schoolteacher'));
        container.querySelector('hits_schoolteacher').classList.add("grid", "grid-cols-2", "md:grid-cols-3", "lg:grid-cols-4", "gap-4");
        container.appendChild(sentinel);

        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !lastRenderArgs.isLastPage) {
              showMore();
            }
          });
        });
        observer.observe(sentinel);

        return;
      }
      container.querySelector('hits_schoolteacher').innerHTML = hits
        .map(
          hit =>
            `<div class="ais-Hits-item">
              <div class="div mx-auto">
                <div class="card">
                  <div class="p-4">
                    <div class="p4 mb-2 flex items-center teacher-profile">
                      <img src="${hit.url}/image" class="teacher-card-pic">
                      <div class="ml-4">
                        <a href="${hit.url}"><h3 class="h6 mb-2 btn-course-show">${hit.name}</h3></a>
                        <div class="p3">${hit.duration}</div>
                      </div>
                    </div>
                    <div class="mb-2 flex items-center subtitle-4">
                      <div class="mr-1 text-eerie-black-100">${hit.rating}</div>
                      <img src="${hit.stars}" class="w-36">
                      <div class="ml-1 text-eerie-black-100">(${hit.number_of_testimonials})</div>
                    </div>
                    <a target="_blank" class="btn-whatsapp text-base rounded font-semibold inline-flex my-2" href="${hit.mobile}"><i class="fab fa-whatsapp text-2xl mr-2 leading-6" aria-hidden="true"></i>Whatsapp</a>
                    <div class="p3 mb-2 pb-2 overflow line-clamp">${hit.one_sentence_pitch}</div>
                    <div class="testimonial mb-4">
                      <img src="${hit.testimonialavatar}" class="small-circle">
                      <div class="ml-2">
                        <div class="subtitle-4 text-left">${hit.username}</div>
                        <div class="p4 text-left overflow line-clamp">${hit.reviews}</div>
                      </div>
                    </div>
                    <a href="${hit.url}"><div class="schedule">See profile and classes</div></a>
                  </div>
                </div>
                <label class="selected-label cursor-pointer" for="selected-profile-${hit.objectID}" onclick="window.compareBannerEducators(${hit.objectID});">
                  <input type="checkbox" name="profiles_ids[]" id="selected-profile-${hit.objectID}" value="${hit.objectID}">
                  <div class="selected-content-compare compare">
                    <div class="flex items-center p3 mx-4 my-3 compare-click">
                      <div class="material-icons text-eerie-black-60 compare-checkbox">check_box_outline_blank</div>
                      <div class="p3 ml-4 compare-content">Select to compare</div>
                    </div>
                  </div>
                </label>
              </div>
            </div>`
        )
        .join('');
    }
  );

  search_educator.addWidgets([
    configure({
      hitsPerPage: 12,
    }),
    searchBox({
      container: "#searchbox_educators",
      placeholder: "Any particular name?",
    }),
    infiniteHits({
      container: document.querySelector('#hits_schoolteacher'),
      templates: {
        // not sure why this no longer works.
        empty: '<div>No matching educator found.</div>',
      },
    }),
  ])
  search_educator.start();
});

