document.addEventListener("turbo:load", () =>  {
  // --------------------------------- New Emergency Contact -----------------------
  const ecBtns = document.querySelectorAll('.ec-btn')
  const closeEcMdls = document.querySelectorAll('.close-ec-modal')

  ecBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      let overlay = btn.closest(".ec-container").querySelector(".ec-overlay")
      overlay.classList.remove('hidden')
      overlay.classList.add('flex')
    })
  })

  closeEcMdls.forEach(mdl => {
    mdl.addEventListener('click', () => {
      let overlay = mdl.closest(".ec-container").querySelector(".ec-overlay")
      overlay.classList.add('hidden')
      overlay.classList.remove('flex')
    })
  })
});
